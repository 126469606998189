export const irlHosts = {
  // Eracm prod
  'irl-us.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    apiPath: '/eyacceleratorseuwacl/acl/api',
    stage: 'prd'
  },
  'irl-au.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    apiPath: '/eyacceleratorseuwacl/acl/api',
    stage: 'prd'
  },
  'irl-training.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-training.ey.com',
    powerappService: 'https://eyaccelerators-training.ey.com',
    apiPath: '/eyacceleratorsuanacl/acl/api',
    stage: 'prd'
  },
  'irl-za.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    apiPath: '/eyacceleratorseuwacl/acl/api',
    stage: 'prd'
  },
  'irl.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    apiPath: '/eyacceleratorseuwacl/acl/api',
    stage: 'prd'
  },
  // Eracm UAT
  'irl-uat.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-uat.ey.com',
    powerappService: 'https://eyaccelerators-uat.ey.com',
    apiPath: '/eyacceleratorsuat/acl/api',
    stage: 'stg'
  },
  // Eracm DEV
  'irl-dev.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-dev.ey.com',
    powerappService: 'https://eyaccelerators-dev.ey.com',
    apiPath: '/eyacceleratorsdevacl/acl/api',
    stage: 'dev'
  },
  'irl-sandbox.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-dev.ey.com',
    powerappService: 'https://eyaccelerators-dev.ey.com',
    apiPath: '/eyacceleratorsdevacl/acl/api',
    stage: 'dev'
  },
  'aramco-dev.irl.ey.com': {
    powerappFrontEnd: 'https://aramco-dev.eyaccelerators.ey.com',
    powerappService: 'https://aramco-dev.eyaccelerators.ey.com',
    apiPath: '/eyaccelerators/api',
    stage: 'dev'
  },
  'aramco-qa.irl.ey.com': {
    powerappFrontEnd: 'https://aramco-qa.eyaccelerators.ey.com',
    powerappService: 'https://aramco-qa.eyaccelerators.ey.com',
    apiPath: '/eyaccelerators/api',
    stage: 'dev'
  },
  'irl-pt.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-dev.ey.com',
    powerappService: 'https://eyaccelerators-dev.ey.com',
    apiPath: '/eyacceleratorsdevacl/acl/api',
    stage: 'dev'
  },
  'localhost:3001': {
    powerappFrontEnd: 'http://localhost:3002',
    stage: 'dev'
  }
}

export const getPowerappFrontEndDependency = (host) => {
  return irlHosts[host].powerappFrontEnd
}

export const getPowerappServiceDependency = (host) => {
  return irlHosts[host].powerappService
}

export const getApiPathDependency = (host) => {
  return irlHosts[host].apiPath
}
