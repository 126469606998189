export const VIA = 'via'
export const ERACM = 'eracm'
export const TPRP = 'tprp'

/**
 * prod env
 * eyvia.ey.com
 * eyvia-de.ey.com
 * eyvia-us.ey.com
 * eyvia-chn.ey.com.cn
 * eyvia-au.ey.com
 * eyvia-ca.ey.com
 * eyvia-uae.ey.com
 * eyvia-training.ey.com
 *
 * staging env
 * eyvia-qa.sbp.eyclienthub.com
 * eyvia-qa2.sbp.eyclienthub.com
 * eyvia-us-staging.ey.com
 * eyvia-stg.ey.com
 * eyvia-de-staging.ey.com
 *
 * dev env
 * via-demo.ey.com
 * eyvia-dev.sbp.eyclienthub.com
 */

const viaHosts = {
  // VIA production
  'eyvia.ey.com': {
    eracmFrontEnd: 'https://irl.ey.com',
    eracmService: 'https://irl.ey.com',
    stage: 'prd'
    // apiKey: '1znvA2YxnKX5sMJ1b2Vdi1nPJ1BVW6LZ',
  },
  'eyvia-de.ey.com': {
    eracmFrontEnd: 'https://irl.ey.com',
    eracmService: 'https://irl.ey.com',
    stage: 'prd'
    // apiKey: '1znvA2YxnKX5sMJ1b2Vdi1nPJ1BVW6LZ',
  },
  'eyvia-us.ey.com': {
    eracmFrontEnd: 'https://irl-us.ey.com',
    eracmService: 'https://irl-us.ey.com',
    stage: 'prd'
    // apiKey: 'aIMvHGyMeV1yOlXELPVSCfbuguPHWjex',
  },
  'eyvia-chn.ey.com.cn': {
    eracmFrontEnd: 'https://irl.ey.com',
    eracmService: 'https://irl.ey.com',
    stage: 'prd'
    // apiKey: '1znvA2YxnKX5sMJ1b2Vdi1nPJ1BVW6LZ',
  },
  'eyvia-au.ey.com': {
    eracmFrontEnd: 'https://irl-au.ey.com',
    eracmService: 'https://irl-au.ey.com',
    stage: 'prd'
    // apiKey: 'fyQwUUd5YdUsfwkEwgvzrTBA5wEKOXae',
  },
  'eyvia-ca.ey.com': {
    eracmFrontEnd: 'https://irl-us.ey.com',
    eracmService: 'https://irl-us.ey.com',
    stage: 'prd'
    // apiKey: 'aIMvHGyMeV1yOlXELPVSCfbuguPHWjex',
  },
  'eyvia-uae.ey.com': {
    eracmFrontEnd: 'https://irl-training.ey.com',
    eracmService: 'https://irl-training.ey.com',
    stage: 'prd'
    // apiKey: 'IO5yiAU1rQJBIiVHXU1G4zis3IzNAaXB',
  },
  'eyvia-training.ey.com': {
    eracmFrontEnd: 'https://irl-training.ey.com',
    eracmService: 'https://irl-training.ey.com',
    stage: 'prd'
    // apiKey: 'IO5yiAU1rQJBIiVHXU1G4zis3IzNAaXB',
  },
  'eyvia-za.ey.com': {
    eracmFrontEnd: 'https://irl-za.ey.com',
    eracmService: 'https://irl-za.ey.com',
    stage: 'prd'
    // apiKey: 'IO5yiAU1rQJBIiVHXU1G4zis3IzNAaXB',
  },
  'eyvia-pt.ey.com': {
    eracmFrontEnd: 'https://irl-pt.ey.com',
    eracmService: 'https://irl-pt.ey.com',
    stage: 'perf'
    // apiKey: 'IO5yiAU1rQJBIiVHXU1G4zis3IzNAaXB',
  },

  // VIA UAT
  'eyvia-us-staging.ey.com': {
    eracmFrontEnd: 'https://irl-uat.ey.com',
    eracmService: 'https://irl-uat.ey.com',
    stage: 'stg'
    // apiKey: 'aIMvHGyMeV1yOlXELPVSCfbuguPHWjex', // w1Uh07j6sKG51qJ7Sp7E1QAwPbQAVoUf
  },
  'eyvia-stg.ey.com': {
    eracmFrontEnd: 'https://irl-uat.ey.com',
    eracmService: 'https://irl-uat.ey.com',
    stage: 'stg'
    // apiKey: 'w1Uh07j6sKG51qJ7Sp7E1QAwPbQAVoUf',
  },
  'eyvia-de-staging.ey.com': {
    eracmFrontEnd: 'https://irl-uat.ey.com',
    eracmService: 'https://irl-uat.ey.com',
    stage: 'stg'
    // apiKey: 'w1Uh07j6sKG51qJ7Sp7E1QAwPbQAVoUf',
  },
  'eyvia-qa2.sbp.eyclienthub.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com', // 'https://irl-uat.ey.com',
    eracmService: 'https://irl-dev.ey.com', // 'https://irl-uat.ey.com',
    stage: 'stg'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy', // 'w1Uh07j6sKG51qJ7Sp7E1QAwPbQAVoUf',
  },

  // VIA dev
  'eyvia-qa.sbp.eyclienthub.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'stg'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  'eyvia-dev.sbp.eyclienthub.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  'via-demo.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  'eyvia-dev1.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
  },
  'eyvia-qa1.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
  },
  'localhost:3000': {
    eracmFrontEnd: 'http://localhost:3001',
    eracmService: 'http://localhost:3001',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  }
}

export const tprpHosts = {
  'aramco-qa.tprp.ey.com': {
    eracmFrontEnd: 'https://aramco-qa.irl.ey.com',
    eracmService: 'https://aramco-qa.irl.ey.com',
    stage: 'dev'
  },
  'aramco-dev.tprp.ey.com': {
    eracmFrontEnd: 'https://aramco-dev.irl.ey.com',
    eracmService: 'https://aramco-dev.irl.ey.com',
    stage: 'dev'
  },

  'tprp-dev.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
  },

  'tprp-qa.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
  },

  'tprp-pt.ey.com': {
    eracmFrontEnd: 'https://irl-pt.ey.com',
    eracmService: 'https://irl-pt.ey.com',
    stage: 'dev'
  },

  'tprp-sit.eu.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
  },

  'tprp-sit.we.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
  },

  'tprp-sandbox.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
  },

  'tprp-sit.ey.com': {
    eracmFrontEnd: 'https://irl-dev.ey.com',
    eracmService: 'https://irl-dev.ey.com',
    stage: 'dev'
  },

  'tprp-uat.ey.com': {
    eracmFrontEnd: 'https://irl-uat.ey.com',
    eracmService: 'https://irl-uat.ey.com',
    stage: 'stg'
  },

  'tprp-uat-we.ey.com': {
    eracmFrontEnd: 'https://irl-uat.ey.com',
    eracmService: 'https://irl-uat.ey.com',
    stage: 'stg'
  },

  'tprp-uat-eu.ey.com': {
    eracmFrontEnd: 'https://irl-uat.ey.com',
    eracmService: 'https://irl-uat.ey.com',
    stage: 'stg'
  },

  'tprp.ey.com': {
    //   This is a front facing url, internally this refers to
    //   "tprp-eu.ey.com" or "tprp-we.ey.com", and this host name will be passed
    //   as Props to the micro frontend
  },

  'tprp-eu.ey.com': {
    eracmFrontEnd: 'https://irl-us.ey.com',
    eracmService: 'https://irl-us.ey.com',
    stage: 'prd'
  },

  'tprp-we.ey.com': {
    eracmFrontEnd: 'https://irl.ey.com',
    eracmService: 'https://irl.ey.com',
    stage: 'prd'
  },

  'localhost:3000': {
    eracmFrontEnd: 'http://localhost:3001',
    eracmService: 'http://localhost:3001',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  }
}

export const eracmHosts = {
  // Eracm prod
  'irl-us.ey.com': {
    eyviaFrontend: 'https://eyvia-us.ey.com',
    eracmService: 'https://irl-us.ey.com',
    appInsightsKey: 'bbcba5fc-7eeb-4afa-84b6-d383502df362',
    stage: 'prd'
    // apiKey: 'aIMvHGyMeV1yOlXELPVSCfbuguPHWjex',
  },
  'irl-au.ey.com': {
    eyviaFrontend: 'https://eyvia-au.ey.com',
    eracmService: 'https://irl-au.ey.com',
    appInsightsKey: '7fd0df0a-95bc-4428-89b3-b42f349b4fe2',
    stage: 'prd'
    // apiKey: 'fyQwUUd5YdUsfwkEwgvzrTBA5wEKOXae',
  },
  'irl-training.ey.com': {
    eyviaFrontend: 'https://eyvia-training.ey.com',
    eracmService: 'https://irl-training.ey.com',
    appInsightsKey: '5bed99be-d5fd-4fbc-a1c8-246bb616bc92',
    stage: 'prd'
    // apiKey: 'IO5yiAU1rQJBIiVHXU1G4zis3IzNAaXB',
  },
  'irl-za.ey.com': {
    eyviaFrontend: 'https://eyvia-za.ey.com',
    eracmService: 'https://irl-za.ey.com',
    appInsightsKey: 'de42328d-88cc-44a4-95f9-c2965de1fc78',
    stage: 'prd'
    // apiKey: 'IO5yiAU1rQJBIiVHXU1G4zis3IzNAaXB',
  },
  'irl.ey.com': {
    eyviaFrontend: 'https://eyvia.ey.com',
    eracmService: 'https://irl.ey.com',
    appInsightsKey: 'e84c2926-4a04-4ec7-b63a-35acaa2303b5',
    stage: 'prd'
    // apiKey: '1znvA2YxnKX5sMJ1b2Vdi1nPJ1BVW6LZ',
  },
  // Eracm UAT
  'irl-uat.ey.com': {
    eyviaFrontend: 'https://eyvia-us-staging.ey.com',
    eracmService: 'https://irl-uat.ey.com',
    appInsightsKey: 'e9fc0c26-340d-46fc-85f9-03d284fbe273',
    stage: 'stg'
    // apiKey: 'w1Uh07j6sKG51qJ7Sp7E1QAwPbQAVoUf',
  },
  // Eracm DEV
  'irl-dev.ey.com': {
    eyviaFrontend: 'https://eyvia-dev.sbp.eyclienthub.com',
    eracmService: 'https://irl-dev.ey.com',
    appInsightsKey: '12700b9e-a7cf-4435-91f2-bd0ef7fd512e',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  'irl-sandbox.ey.com': {
    eyviaFrontend: 'https://eyvia-dev.sbp.eyclienthub.com',
    eracmService: 'https://irl-sandbox.ey.com',
    appInsightsKey: '3e6ca929-0f36-4124-a45d-446682c5f73d',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  'irl-pt.ey.com': {
    eyviaFrontend: 'https://eyvia-pt.sbp.eyclienthub.com',
    eracmService: 'https://irl-pt.ey.com',
    appInsightsKey: 'c1fae5d9-1c5a-4cbc-9892-f3759c834bd1',
    stage: 'perf'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  'aramco-dev.irl.ey.com': {
    eyviaFrontend: 'https://eyvia-dev.sbp.eyclienthub.com',
    eracmService: 'https://aramco-dev.irl.ey.com',
    appInsightsKey: '3e6ca929-0f36-4124-a45d-446682c5f73d',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  'aramco-qa.irl.ey.com': {
    eyviaFrontend: 'https://eyvia-dev.sbp.eyclienthub.com',
    eracmService: 'https://aramco-dev.irl.ey.com',
    appInsightsKey: '3e6ca929-0f36-4124-a45d-446682c5f73d',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  'localhost:3001': {
    eyviaFrontend: 'http://localhost:3000',
    stage: 'dev'
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  }
}

export const getMicroFeDependency = (host) => {
  if (eracmHosts[host]) {
    return eracmHosts[host].eyviaFrontend
  } else if (viaHosts[host]) {
    return viaHosts[host].eracmFrontEnd
  } else if (tprpHosts[host]) {
    return tprpHosts[host].eracmFrontEnd
  }
  return null
}

export const getEracmServiceDependency = (host) => {
  if (eracmHosts[host]) {
    return eracmHosts[host].eracmService
  } else if (viaHosts[host]) {
    return viaHosts[host].eracmService
  } else if (tprpHosts[host]) {
    return tprpHosts[host].eracmService
  }
  return null
}

export const getEracmAppInsightsInfo = (host) => {
  if (eracmHosts[host]) {
    const { appInsightsKey = null, stage = '' } = eracmHosts[host]
    return {
      appInsightsKey,
      stage
    }
  }
  return { appInsightsKey: null, stage: '' }
}

export const getAppName = (host) => {
  if (eracmHosts[host]) {
    return ERACM
  } else if (viaHosts[host]) {
    return VIA
  } else if (tprpHosts[host]) {
    return TPRP
  }
  return null
}

export const isNonProdEnv = (host) => {
  return eracmHosts[host].stage !== 'prd'
}
